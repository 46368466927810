.ag-theme-material {
  --ag-font-family: 'Montserrat', sans-serif;
  --ag-font-size: 14px;
  --ag-header-column-separator-display: block;
  --ag-cell-horizontal-border: solid 1px #e0e2e4;
}

.ag-theme-material .ag-header {
  font-weight: 700;
  font-size: 12px;
}
